import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "tasks",
  data() {
    const nTaskList = [{
      label: this.$lang("Join channel"),
      target: "channel",
      icon: "tp"
    }, {
      label: this.$lang("Folow us on X"),
      target: "twitter",
      value: 1,
      icon: "twitter"
    }, {
      label: this.$lang("Connect wallet"),
      target: "wallet",
      icon: "ton"
    }, {
      label: this.$lang("I am not a robot"),
      target: "facescan",
      icon: "facescan"
    }];
    return {
      nTaskList,
      popBindWalletShow: false,
      taskNum: 0,
      extraTaskNum: 0,
      checkNum: 0,
      timeVal: null,
      stimeVal: null,
      popFaceScanShow: false,
      itemData: {},
      popEarnTipShow: false
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["taskList", "advList"])
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timeVal);
    clearTimeout(this.stimeVal);
    this.popBindWalletShow = false;
    this.popFaceScanShow = false;
    this.popEarnTipShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "userData": {
      handler() {
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "taskList": {
      handler(newVal, oldVal) {
        console.log("this.taskList", this.taskList);
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "advList": {
      handler(newVal, oldVal) {
        console.log("this.advList", this.advList);
        this.checkTask();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...userVuex.mapActions(["join"]),
    ...userVuex.mapMutations(["setUserData"]),
    ...taskVuex.mapActions(['getTaskList', "getAdvList"]),
    onSkip(page) {
      this.$router.push(`/${page}`);
    },
    async onJoin(item) {
      if (item.target == "channel" || item.target == "telegram") {
        this.WebApp.openTelegramLink(this.setting.socials[item.target]);
      } else if (item.target == "wallet") {
        this.popBindWalletShow = true;
        return;
      } else if (item.target == "facescan") {
        this.popFaceScanShow = true;
        return;
      } else {
        this.WebApp.openLink(this.setting.socials[item.target]);
      }
      if (!this.userData[`joined_${item.target}`]) {
        await this.join([item.target, null, 1]);
      }
    },
    async onExtralJoin(item, adv = 0) {
      if ((item.type || '') == 'adsgram' || (item.provider || '') == 'adsgram') {
        this.$loading.show();
        const AdController = await window.Adsgram.init({
          blockId: item.url
        });
        await AdController.show().then(result => {
          // user watch ad till the end
          // your code to reward user
          this.$loading.hide();
          if (result.done && !item.completed) this.checkExtralTask(item, adv);
        }).catch(result => {
          // user skipped video or get error during playing ad
          // do nothing or whatever you want
          console.log(result);
          this.$loading.hide();
        });
        this.$loading.hide();
        return;
      } else if ((item.type || '') == 'matrix') {
        if (!item.completed) this.$router.push(`/taskDetail?id=${item.id}`);
      }
      // else{
      //   this.WebApp.openLink(item.url)
      // }
      // this.checkNum = 0;
      // if(!item.completed)
      //   this.checkExtralTask(item,adv);

      this.itemData = item;
      this.itemData.adv = adv;
      this.popEarnTipShow = true;
    },
    async checkExtralTask(item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        if (adv == 0) {
          await this.loadTaskCount();
          this.checkTask();
        }
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      }
    },
    checkTask() {
      this.taskNum = 0;
      this.extraTaskNum = 0;
      if (!this.userData.joined_wallet) this.taskNum += 1;
      for (let key in this.setting.socials) {
        if (this.setting.socials[key]) {
          switch (key) {
            case "channel":
              if (this.userData.joined_channel == 0) {
                this.taskNum += 1;
              }
              break;
            case "discord":
              if (this.userData.joined_discord == 0) {
                this.taskNum += 1;
              }
              break;
            case "telegram":
              if (this.userData.joined_telegram == 0) {
                this.taskNum += 1;
              }
              break;
            case "twitter":
              if (this.userData.joined_twitter == 0) {
                this.taskNum += 1;
              }
              break;
          }
        }
      }
      this.taskList.map(item => {
        if (item.completed == 0) {
          this.extraTaskNum += 1;
        }
      });
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.advList.map(item => {
        if (item.cyclical == 1 && item.countdown > 0) {
          item.countdown = item.countdown - 1;
          if (item.countdown <= 0) {
            item.completed = 0;
          }
        }
      });
      this.taskList.map(item => {
        if (item.cyclical == 1 && item.completed == 1) {
          item.countdown = item.countdown - 1;
          if (item.countdown <= 0) {
            item.completed = 0;
          }
        }
      });
      this.timeVal = setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    advCountDown() {
      clearTimeout(this.stimeVal);
      this.itemData.adv_countdown = this.itemData.adv_countdown - 1;
      if (this.itemData.adv_countdown <= 0) {
        this.itemData.completed = 1;
        this.checkExtralTask(this.itemData, this.itemData.adv);
      } else {
        this.stimeVal = setTimeout(() => {
          this.advCountDown();
        }, 1000);
      }
    }
  },
  async mounted() {
    if (this.taskList.length == 0) await this.getTaskList();
    this.checkTask();
    await this.getAdvList();
    this.countDown();
  }
};