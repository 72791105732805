import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {
        if (this.userData.id) this.isLoading = true;
        if (this.userData.in_app && !["/home", "/tasks", "/learderboard", "/frends", "/airdrop", "/sign"].includes(this.route)) {
          console.log("this.userData.in_app", this.userData.in_app);
          this.$router.push("/home");
        }
      },
      immediate: true,
      deep: true
    },
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("$route.path", newPath, oldPath);
        if (newPath === "/" || newPath === "/home") {
          this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
          this.WebApp.isClosingConfirmationEnabled = true;
          return;
        }
        this.WebApp.BackButton.show();
      },
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "loadTaskCount"]),
    ...userVuex.mapActions(["login", "getUserData"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    initStatistics() {
      document.title = this.setting.coin;
      if (this.setting.statistics.length > 0) {
        this.setting.statistics.map(item => {
          if (item.type == "plausible" && item.value) {
            const pScript = document.createElement("script");
            pScript.src = `https://plausible.io/js/script.js`;
            pScript.defer = true;
            pScript.setAttribute("data-domain", item.value);
            document.head.appendChild(pScript);
          }
          if (item.type == "google" && item.value) {
            const gaScript = document.createElement("script");
            gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${item.value}`;
            gaScript.async = true;
            gaScript.onload = () => {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", item.value);
            };
            document.head.appendChild(gaScript);
          }
        });
      }
    },
    onlineRefresh() {
      if (this.userData) this.$http.post("/user/online/refresh", {});
      setTimeout(() => {
        this.onlineRefresh();
      }, 180 * 1000);
    }
  },
  async mounted() {
    console.dir(document.body);
    document.body.style.background = this.themeMainColor(this.theme).main;
    await this.getSetting();
    await this.login([this.WebApp.initData, this.WebApp.initDataUnsafe.start_param || ""]);
    //await this.login(["user=%7B%22id%22%3A1989068375%2C%22first_name%22%3A%22Yong%22%2C%22last_name%22%3A%22Goo%22%2C%22username%22%3A%22Yanggooo%22%2C%22language_code%22%3A%22zh-hans%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2Fpk0goIXyHnrjBnT3wyU-m_J-nQafmLVP-Wdu-Vz954Y.svg%22%7D&chat_instance=-4810064575269763367&chat_type=private&auth_date=1737540233&signature=nkCV7YwmwPq9LYf_Olzqnr_f523uWpm5z9utwoGrhVkzcl8ikZWJHwbR8rp4NI2WHQyR-hCV9ltuN7s44ZbHCQ&hash=7750e61732df3ae9a4af09cbe8de4121db064ec5e1a721de40828417ce2a6403", this.WebApp.initDataUnsafe.start_param || ""]);
    this.initStatistics();
    this.WebApp.BackButton.onClick(() => {
      this.$router.go(-1);
    });
    setTimeout(() => {
      console.log("refresh");
      this.onlineRefresh();
    }, 180 * 1000);
    this.WebApp.isClosingConfirmationEnabled = true;
    this.WebApp.disableVerticalSwipes();
    if ((this.WebApp.initDataUnsafe.start_param || "").indexOf("page_") !== -1) {
      let tstart_param = (this.WebApp.initDataUnsafe.start_param || "").split("-");
      let page = "";
      let id = "";
      let inviteCode = "";
      tstart_param.map(item => {
        if (item.indexOf("page_") !== -1) {
          page = item.replace("page_", "");
        }
        if (item.indexOf("id_") !== -1) {
          id = item.replace("id_", "");
        }
        if (item.indexOf("ref_") !== -1) {
          inviteCode = item.replace("ref_", "");
        }
      });
      if (page) {
        let url = `/${page}${id ? `?id=${id}` : ""}`;
        if (inviteCode) {
          if (url.indexOf("?") !== -1) {
            url += "&invitecode=" + inviteCode;
          } else {
            url += "?invitecode=" + inviteCode;
          }
        }
        this.$router.push(url);
      }
    }
  }
};